<template>
  <div class="remind_container">
    <div class="d1"><strong>数亿付提醒您</strong></div>
    <img src="@/assets/common/scan.png" alt="">
    <div class="d2">请使用 <strong>支付宝APP，微信APP，云闪付App“扫一扫”</strong> 来完成付款</div>
  </div>
</template>

<script>
export default {
  name: 'Remind'
}
</script>

<style lang="scss" scoped>
  @import '../../styles/mixin.scss';

  .remind_container {
    max-width: 750px;
    position: fixed;
    height: 100vh;
    z-index: 9;
    @include CCFS;
    padding: 0 30px;
    img {
      width: 100px;
      height: 100px;
      margin: 30px 0;
    }
    .d1 {
      font-size: 32px;
      color: #000;
      padding-top: 30%;
    }
    .d2 {
      color: #000;
      font-size: 20px;
      line-height: 40px;
      strong {
        font-size: 30px;
      }
    }
  }
</style>
